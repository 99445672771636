import React, { Component } from "react";

import { cleanVerboseText, no_image } from 'js-base-utils'

export default class TvCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
          toggle: false,
          mainWidth: window.innerWidth/4,
          marginWidth: window.innerWidth/50,
          imageWidth: window.innerWidth/4 - window.innerWidth/50,
          fontSize: "16px"
        };
    }

    componentDidMount() {
        setInterval(this.toggleDescription.bind(this), 10000);
    }

    toggleDescription() {
        var toggle = this.state.toggle;
        this.setState({toggle: !toggle});
    }

    render() {
        const marginWidth= window.innerWidth/this.props.marginFraction;
        const imageWidth= (window.innerWidth/this.props.cardsPerRow - window.innerWidth/this.props.marginFraction) - this.props.cardsPerRow*0.25;
        const fontSize= this.props.fontSize;
        const priceFontSize= this.props.priceFontSize;
        const descriptionFontSize= this.props.descriptionFontSize;
        const descriptionHeightPercentage = this.props.descriptionHeightPercentage;
        const topInfoFontSize = this.props.topInfoFontSize;
        const topInfoHeightPercentage = this.props.topInfoHeightPercentage;
        const infobarHeightPercentage = this.props.infobarHeightPercentage;
        const addressHeightPercentage = this.props.addressHeightPercentage;
        return (
            <div style={{float: "left", position: "relative", margin: `${marginWidth/4}px ${marginWidth/2}px ${marginWidth/4}px ${marginWidth/2}px`, border: `gray ${this.props.no_border ? '0' : '0.5px'} solid`,}}>

                <img src={this.props.property.image ? this.props.property.image : no_image} alt={this.props.property.id} width={(imageWidth)} height={0.75*((imageWidth))} />
                
                <div style={{backgroundColor: "#ac252d", position: "absolute", top: 0, right: 0, width: imageWidth*0.75, height: (0.75*((imageWidth))*(topInfoHeightPercentage)), textAlign: "center", overflow: "hidden", color: "#fff", fontSize: `${topInfoFontSize}px`, fontWeight: "bold"}}>
                    {cleanVerboseText(this.props.property.neighborhood, /JARDIM|PARQUE|CONDOMÍNIO|VILA|RESIDENCIAL|CONJUNTO HABITACIONAL|FAZENDA|COLINAS|LOTEAMENTO/gi, "")}
                </div>

                <div>
                    <div style={{backgroundColor: "#fff", float: "left", width: ((imageWidth)*0.25), height: (0.75*((imageWidth))*infobarHeightPercentage), textAlign: "center", overflow: "hidden", color: "#333", fontSize: `${fontSize}px`, fontWeight: "bold"}}>
                        {this.props.property.id}
                    </div>
                    <div style={{backgroundColor: "#ac252d", float: "left", width: ((imageWidth)*0.5), height: (0.75*((imageWidth))*infobarHeightPercentage), textAlign: "center", overflow: "hidden", color: "#fff", fontSize: `${priceFontSize}px`, fontWeight: "bold"}}>
                        {this.props.sale_card ? this.props.property.sale_price : this.props.property.total_rent_price}
                    </div>
                    <div style={{backgroundColor: "#fff", float: "left", width: ((imageWidth)*0.25), height: (0.75*((imageWidth))*infobarHeightPercentage), textAlign: "center", overflow: "hidden", color: "#333", fontSize: `${fontSize}px`, fontWeight: "bold"}}>
                        {this.props.property.keys_id}
                    </div>
                </div>

                <div style={{backgroundColor: "#ccc", float: "left", width: (imageWidth), height: (0.75*((imageWidth))*addressHeightPercentage), textAlign: "center", overflow: "hidden", color: "#333", fontSize: `${fontSize-2}px`, fontWeight: "bold", textTransform: "uppercase"}}>
                {this.props.displayNumber && this.props.property.number} {this.props.property.kind_street} {this.props.property.street}
                </div>

                {!this.props.hideComplement &&
                    <div>
                        <div style={{backgroundColor: "#333", float: "left", width: ((imageWidth)*0.75), height: (0.75*((imageWidth))*addressHeightPercentage), textAlign: "center", overflow: "hidden", color: "#fff", fontSize: `${fontSize-2}px`, fontWeight: "bold"}}>
                            {this.props.property.complement}
                        </div>
                        <div style={{backgroundColor: "#333", float: "left", width: ((imageWidth)*0.25), height: (0.75*((imageWidth))*addressHeightPercentage), textAlign: "center", overflow: "hidden", color: "#fff", fontSize: `${fontSize-2}px`, fontWeight: "bold"}}>
                            Nº {this.props.property.number}
                        </div>
                    </div>
                }

                {this.props.displayDescription &&
                    <div>
                        <div style={{backgroundColor: "#FFF", float: "left", width: ((imageWidth)*1), height: (0.75*((imageWidth))*descriptionHeightPercentage), textAlign: "justify", overflow: "hidden", color: "#333", fontSize: `${descriptionFontSize}px`, fontWeight: "normal"}}>
                            {this.props.property.description}
                        </div>
                    </div>
                }
                
            </div>
        );
    }
}