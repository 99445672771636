import React, { Component } from "react";
import * as actionUtilities from '../components/api/index';
import { currencyToDecimal } from 'js-base-utils'
import { GetRequestSWR } from "../lib/fetcher"


import TvCardFull from "../components/cards/TvCardFull"

export default function App() {
  
  const { isLoading, data, error, cache } = GetRequestSWR(
    `/static_pages/available_properties?&per_page=1000&status%5B%5D=Disponível&kind_property%5B%5D=Casa&bedroom%5B%5D=1&allow_site=true&only_rent=true&city%5B%5D=AGUAÍ`,
    null,
    true,
    null,
    true,
    1000 * 60 * 5,
    true,
  )

  if (error) {
    return <p>Desculpe, ocorreu um erro!</p>;
  }

  return (
    <div style={{ overflow: "hidden", }}>

      <div style={{ overflow: "hidden", backgroundColor: '#fff', width: '100%', height: "100vh", float: "left" }}>
        <div style={{ backgroundColor: '#333', width: '100%' }}>
          <div style={{ color: '#fff', fontSize: '40px', fontWeight: 'bold', fontFamily: 'Georgia, Serif', textAlign: "center" }}>
            Casas para Alugar - 01 Dormitório
          </div>
        </div>
        <div>
          {data?.properties.sort(function (a, b) { return currencyToDecimal(b.total_rent_price) - currencyToDecimal(a.total_rent_price) }).map((property) => (
            <TvCardFull
            property={property}
            rent_card={true}
            cardsPerRow={6}
            marginFraction={500}
            no_border={true}
            fontSize={18}
            priceFontSize={20}
            displayDescription={true}
            descriptionFontSize={16}
            descriptionHeightPercentage={0.4}
            topInfoFontSize={18}
            topInfoHeightPercentage={0.14}
            infobarHeightPercentage={0.15}
            addressHeightPercentage={0.12}
            />
          ))}
        </div>
      </div>

    </div>
  );
}


// export default function App() {
  
//   const { isLoading, data, error, cache } = GetRequestSWR(
//     `/static_pages/available_properties?&per_page=1000&status%5B%5D=Disponível&kind_property%5B%5D=Casa&bedroom%5B%5D=1&allow_site=true&only_rent=true&city%5B%5D=AGUAÍ`,
//     null,
//     true,
//     true,
//     null,
//     true,
//     1000 * 60 * 5,
//     true,
//   )

//   if (error) {
//     return <p>Desculpe, ocorreu um erro!</p>;
//   }

//   return (
//     <div style={{ overflow: "hidden", }}>

//       <div style={{ overflow: "hidden", backgroundColor: '#fff', width: '100%', height: "100vh", float: "left" }}>
//         <div style={{ backgroundColor: '#333', width: '100%' }}>
//           <div style={{ color: '#fff', fontSize: '40px', fontWeight: 'bold', fontFamily: 'Georgia, Serif', textAlign: "center" }}>
//             Casas para Alugar - 01 Dormitório
//           </div>
//         </div>
//         <div>
//           {data?.properties.sort(function (a, b) { return currencyToDecimal(b.total_rent_price) - currencyToDecimal(a.total_rent_price) }).map((property) => (
//             <TvCardFull
//               property={property}
//               rent_card={true}
//               cardsPerRow={8}
//               marginFraction={400}
//               fontSize={14}
//               priceFontSize={14}
//               displayDescription={false}
//               descriptionFontSize={10}
//               descriptionHeightPercentage={0.4}
//               topInfoFontSize={14}
//               topInfoHeightPercentage={0.14}
//               infobarHeightPercentage={0.15}
//               addressHeightPercentage={0.12}
//               descriptionHeightPercentage={0.2}
//             />
//           ))}
//         </div>
//       </div>

//     </div>
//   );
// }