import React, { Component } from "react";
import * as actionUtilities from '../components/api/index';
import { currencyToDecimal } from 'js-base-utils'


import TvCardFull from "../components/cards/TvCardFull"

const changePageSeconds = 45;


export default class HorizontalScreen01 extends Component {

  constructor(props) {
      super(props);
      this.state = {
          properties1: [],
          properties2: [],
          properties3: [],
      };
  }

  async componentDidMount() {
      var properties1 = await actionUtilities.dataRequest(
          `/static_pages/available_properties?&per_page=1000&status%5B%5D=Disponível&kind_property%5B%5D=Casa&only_rent=true&city%5B%5D=SÃO JOÃO DA BOA VISTA`,
          "GET",
          null,
          "HTTP_PROPERTIES",
          "",
          (callback) => this.setState({ loadingMore: callback["loading"] }));

      var properties2 = await actionUtilities.dataRequest(
          `/static_pages/available_properties?&per_page=1000&status%5B%5D=Disponível&kind_property%5B%5D=Apartamento&only_rent=true&city%5B%5D=SÃO JOÃO DA BOA VISTA`,
          "GET",
          null,
          "HTTP_PROPERTIES",
          "",
          (callback) => this.setState({ loadingMore: callback["loading"] }));

      var properties3 = await actionUtilities.dataRequest(
          `/static_pages/available_properties?&per_page=1000&status%5B%5D=Disponível&kind_property%5B%5D=Sala Comercial&kind_property%5B%5D=Barracão Comercial&only_rent=true&city%5B%5D=SÃO JOÃO DA BOA VISTA`,
          "GET",
          null,
          "HTTP_PROPERTIES",
          "",
          (callback) => this.setState({ loadingMore: callback["loading"] }));

      this.setState({ properties2: properties2.properties, properties1: properties1.properties, properties3: properties3.properties });
      setTimeout(this.autoRefresh.bind(this), 1800000);
      this.timer = setInterval(() => {
        window.location.reload();
      }, 5 * 60 * 1000);
    }
  
    componentWillUnmount() {
      // Clear the timer when the component unmounts
      clearInterval(this.timer);
    }

  autoRefresh() {
      window.location.reload();
  }

  render() {
      if (this.props.hasErrored) {
          return <p>Desculpe, ocorreu um erro!</p>;
      }

      return (

          <div style={{ overflow: "hidden", }}>

              <div style={{ overflow: "hidden", backgroundColor: '#fff', width: '100%', height: "35vh", float: "left" }}>
                  <div style={{ backgroundColor: '#333', width: '100%' }}>
                      <div style={{ color: '#fff', fontSize: '26px', fontWeight: 'bold', fontFamily: 'Georgia, Serif', textAlign: "center" }}>
                          Casas para Alugar - 01, 02 ou 03+ Dormitórios
              </div>
                  </div>
                  <div>
                      {this.state.properties1.sort(function (a, b) { return currencyToDecimal(b.total_rent_price) - currencyToDecimal(a.total_rent_price) }).map((property, key) => (
                          <TvCardFull
                              key={key}
                              property={property}
                              cardsPerRow={11}
                              marginFraction={400}
                              fontSize={10}
                              priceFontSize={9}
                              displayDescription={false}
                              descriptionFontSize={12}
                              descriptionHeightPercentage={0.4}
                              topInfoFontSize={10}
                              topInfoHeightPercentage={0.16}
                              infobarHeightPercentage={0.12}
                              addressHeightPercentage={0.09}
                              rent_card={true}
                              sale_card={false}
                          />
                      ))}
                  </div>
              </div>

              <div style={{ overflow: "hidden", backgroundColor: '#fff', width: '100%', height: "22vh", float: "left" }}>
                  <div style={{ backgroundColor: '#333', width: '100%' }}>
                      <div style={{ color: '#fff', fontSize: '26px', fontWeight: 'bold', fontFamily: 'Georgia, Serif', textAlign: "center" }}>
                          Apartamentos e Kitnets - 01, 02 ou 03+ Dormitórios
              </div>
                  </div>
                  <div>
                      {this.state.properties2.sort(function (a, b) { return currencyToDecimal(b.total_rent_price) - currencyToDecimal(a.total_rent_price) }).map((property, key) => (
                          <TvCardFull
                              key={key}
                              property={property}
                              cardsPerRow={10}
                              marginFraction={400}
                              fontSize={10}
                              priceFontSize={10}
                              displayDescription={false}
                              descriptionFontSize={12}
                              descriptionHeightPercentage={0.4}
                              topInfoFontSize={12}
                              topInfoHeightPercentage={0.16}
                              infobarHeightPercentage={0.12}
                              addressHeightPercentage={0.09}
                              rent_card={true}
                              sale_card={false}
                          />
                      ))}
                  </div>
              </div>

              <div style={{ overflow: "hidden", backgroundColor: '#fff', width: '100%', height: "44vh", float: "left" }}>
                  <div style={{ backgroundColor: '#333', width: '100%' }}>
                      <div style={{ color: '#fff', fontSize: '26px', fontWeight: 'bold', fontFamily: 'Georgia, Serif', textAlign: "center" }}>
                          Salas e Barracões Comerciais para Alugar
              </div>
                  </div>
                  <div>
                      {this.state.properties3.sort(function (a, b) { return currencyToDecimal(b.total_rent_price) - currencyToDecimal(a.total_rent_price) }).map((property, key) => (
                          <TvCardFull
                              key={key}
                              property={property}
                              cardsPerRow={9}
                              marginFraction={400}
                              fontSize={10}
                              priceFontSize={9}
                              displayDescription={false}
                              descriptionFontSize={12}
                              descriptionHeightPercentage={0.4}
                              topInfoFontSize={12}
                              topInfoHeightPercentage={0.16}
                              infobarHeightPercentage={0.12}
                              addressHeightPercentage={0.09}
                              rent_card={true}
                              sale_card={false}
                          />
                      ))}
                  </div>
              </div>


          </div>
      );
  }
} 