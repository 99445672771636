import React, { Component } from "react";
import * as actionUtilities from '../components/api/index';
import { currencyToDecimal } from 'js-base-utils'


import TvCardFull from "../components/cards/TvCardFull"

const changePageSeconds = 45;


export default class HorizontalScreen01 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      properties1: [],
      page: 1,
      per_page: 32,
      numberPages: 0,
      start_range: 0,
      end_range: 0,
      changeTime: changePageSeconds,
    };
  }

  async componentDidMount() {
    var properties1 = await actionUtilities.dataRequest(
      `/static_pages/available_properties?&per_page=1000&status%5B%5D=Disponível&allow_site=true&only_sale=true&bedroom%5B%5D=1&bedroom%5B%5D=2&city%5B%5D=SÃO JOÃO DA BOA VISTA`,
      "GET",
      null,
      "HTTP_PROPERTIES",
      "",
      (callback) => this.setState({ loadingMore: callback["loading"] })
    );
    this.setState({ properties1: properties1.properties });

    const total = Math.ceil(
      this.state.properties1.length / this.state.per_page
    );
    this.setState({ total, end_range: this.state.per_page - 1 });
    setTimeout(
      this.displayNext.bind(
        this,
        this.state.properties1.length,
        this.state.per_page,
        this.state.page + 1
      ),
      changePageSeconds * 1000
    );
    setInterval(this.countdown.bind(this), 1000);
    setTimeout(this.autoRefresh.bind(this), 1800000);
    this.timer = setInterval(() => {
      window.location.reload();
    }, 5 * 60 * 1000);
  }

  componentWillUnmount() {
    // Clear the timer when the component unmounts
    clearInterval(this.timer);
  }

  autoRefresh() {
    window.location.reload();
  }

  displayNext(length, per_page, page) {
    var properties = this.state.properties1;
    this.setState({ properties1: [] });
    var numberPages = Math.ceil(length / per_page);
    if (page >= 1 && page <= numberPages) {
      this.setState({
        properties1: properties,
        page,
        start_range: (page - 1) * per_page,
        end_range: page * per_page - 1,
      });
    } else {
      this.setState({
        properties1: properties,
        page: 1,
        start_range: 0,
        end_range: per_page - 1,
      });
    }
    setTimeout(
      this.displayNext.bind(
        this,
        this.state.properties1.length,
        this.state.per_page,
        this.state.page + 1
      ),
      changePageSeconds * 1000
    );
  }

  countdown() {
    var changeTime = this.state.changeTime;
    changeTime = changeTime - 1;
    if (changeTime <= 0) {
      this.setState({ changeTime: changePageSeconds });
    } else {
      this.setState({ changeTime });
    }
  }

  render() {
    if (this.props.hasErrored) {
      return <p>Desculpe, ocorreu um erro!</p>;
    }

    return (
      <div style={{ overflow: "hidden", cursor: "none" }}>
        <div style={{ width: "100%", maxHeight: "100vh", overflow: "hidden" }}>
          <div style={{ backgroundColor: "#333", width: "100%" }}>
            <div
              style={{
                color: "#fff",
                fontSize: "32px",
                fontWeight: "bold",
                fontFamily: "Georgia, Serif",
                textAlign: "center",
              }}
            >
              Venda - 01 ou 02 Dormitórios
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#ac252d",
              width: "100%",
              margin: "0 0 0 0",
            }}
          >
            <div
              style={{
                color: "#fff",
                fontSize: "24px",
                fontWeight: "bold",
                fontFamily: "Georgia, Serif",
                textAlign: "center",
              }}
            >
              Página {this.state.page} de {Math.ceil(this.state.properties1?.length / this.state.per_page) || 1}
            </div>
          </div>
          {this.state.properties1
            .sort(function (a, b) {
              return (
                currencyToDecimal(b.sale_price) -
                currencyToDecimal(a.sale_price)
              );
            })
            .filter(
              (el, index) =>
                index >= this.state.start_range && index <= this.state.end_range
            )
            .map((property, index) => (
              <TvCardFull
                key={index}
                property={property}
                sale_card={true}
                cardsPerRow={8}
                marginFraction={400}
                fontSize={12}
                priceFontSize={12}
                displayDescription={false}
                descriptionFontSize={11}
                descriptionHeightPercentage={0.4}
                topInfoFontSize={14}
                topInfoHeightPercentage={0.12}
                infobarHeightPercentage={0.12}
                addressHeightPercentage={0.1}
              />
            ))}
        </div>
      </div>
    );
  }
}
