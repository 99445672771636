import React, { Component } from "react";
import * as actionUtilities from "../components/api/index";
import { currencyToDecimal } from "js-base-utils";
import { GetRequestSWR } from "../lib/fetcher";

import TvCardFull from "../components/cards/TvCardFull";

export default function App() {
  return (
    <div style={{ overflow: "hidden" }}>
      <div
        style={{
          overflow: "hidden",
          backgroundColor: "#fff",
          width: "100%",
          height: "67vh",
          float: "left",
        }}
      >
        <div style={{ backgroundColor: "#333", width: "100%" }}>
          <div
            style={{
              color: "#fff",
              fontSize: "26px",
              fontWeight: "bold",
              fontFamily: "Georgia, Serif",
              textAlign: "center",
            }}
          >
            Casas para Alugar - 03 Dormitórios
          </div>
        </div>
        <div>{three_dorms()}</div>
      </div>

      <div
        style={{
          overflow: "hidden",
          backgroundColor: "#fff",
          width: "100%",
          height: "33vh",
          float: "left",
        }}
      >
        <div style={{ backgroundColor: "#333", width: "100%" }}>
          <div
            style={{
              color: "#fff",
              fontSize: "26px",
              fontWeight: "bold",
              fontFamily: "Georgia, Serif",
              textAlign: "center",
            }}
          >
            Apartamentos para Alugar
          </div>
        </div>
        <div>{aptos()}</div>
      </div>
    </div>
  );
}

function three_dorms() {
  const { isLoading, data, error, cache } = GetRequestSWR(
    `/static_pages/available_properties?&per_page=1000&status%5B%5D=Disponível&kind_property%5B%5D=Casa&bedroom%5B%5D=3&allow_site=true&only_rent=true&city%5B%5D=AGUAÍ`,
    null,
    true,
    null,
    true,
    1000 * 60 * 5,
    true
  );

  return (
    <div>
      {data?.properties.sort(function (a, b) { return currencyToDecimal(b.total_rent_price) - currencyToDecimal(a.total_rent_price) }).map((property) => (
        <TvCardFull
        property={property}
        rent_card={true}
        cardsPerRow={8}
        marginFraction={10000000000000000000000000000000000000}
        fontSize={18}
        priceFontSize={17}
        displayDescription={true}
        descriptionFontSize={12}
        descriptionHeightPercentage={0.5}
        topInfoFontSize={18}
        topInfoHeightPercentage={0.14}
        infobarHeightPercentage={0.15}
        addressHeightPercentage={0.12}
        />
      ))}
    </div>
  );
}

function aptos() {
  const { isLoading, data, error, cache } = GetRequestSWR(
    `/static_pages/available_properties?&per_page=1000&status%5B%5D=Disponível&kind_property%5B%5D=Apartamento&allow_site=true&only_rent=true&city%5B%5D=AGUAÍ`,
    null,
    true,
    null,
    true,
    1000 * 60 * 5,
    true
  );

  return (
    <div>
      {data?.properties.sort(function (a, b) { return currencyToDecimal(b.total_rent_price) - currencyToDecimal(a.total_rent_price) }).map((property) => (
        <TvCardFull
        property={property}
        rent_card={true}
        cardsPerRow={7}
        marginFraction={1000}
        fontSize={16}
        priceFontSize={17}
        displayDescription={false}
        descriptionFontSize={10}
        descriptionHeightPercentage={0.4}
        topInfoFontSize={16}
        topInfoHeightPercentage={0.14}
        infobarHeightPercentage={0.15}
        addressHeightPercentage={0.12}
        />
      ))}
    </div>
  );
}
