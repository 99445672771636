import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import { api_version, main_server } from "./api.js";

const fetcher = (url) => fetch(url).then((res) => res.json());

export const GetRequestSWR = (url) => {
  url = `${main_server}${api_version}${url}`;

  const { data, error } = useSWR(url, fetcher, {
    refreshInterval: 5 * 60 * 1000,
  });

  console.log(data);
  const { cache, mutate } = useSWRConfig();

  return {
    isLoading: !data && !error,
    data,
    error,
    mutate,
    cache,
  };
};
