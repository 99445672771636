import { Routes, Route, Outlet, Link } from "react-router-dom";
import Screen01 from "./pages/screen01";
import Screen02 from "./pages/screen02";
import Screen03 from "./pages/screen03";
import Screen04 from "./pages/screen04";
import SjbvScreen01 from "./pages/screen_sjbv01";
import SjbvScreen02 from "./pages/screen_sjbv02";
import SjbvScreen03 from "./pages/screen_sjbv03";
import SjbvScreen04 from "./pages/screen_sjbv04";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="screen01" element={<Screen01 />} />
        <Route path="screen02" element={<Screen02 />} />
        <Route path="screen03" element={<Screen03 />} />
        <Route path="screen04" element={<Screen04 />} />
        <Route path="screen_sjbv01" element={<SjbvScreen01 />} />
        <Route path="screen_sjbv02" element={<SjbvScreen02 />} />
        <Route path="screen_sjbv03" element={<SjbvScreen03 />} />
        <Route path="screen_sjbv04" element={<SjbvScreen04 />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
