// import variables
import { main_server, api_version } from './variables'

export function dataRequest(url, method, body, type, token, callback) {
    // Callback - loading
    if (callback) { callback({loading: true, status: null}) }
    // URL
    url = main_server + api_version + url;
    // HTTP Config
    let config = {
      method: method,
      headers: {
        'Content-Type': method === 'UPLOAD' ? 'multipart/form-data' : 'application/json',
        'Authorization': token,
      },
    };
    // IF POST, PATCH, DELETE -> Add Body
    if (body) { config.body = JSON.stringify(body) };
    // IF method -> UPLOAD
    if (method === 'UPLOAD') {
        config.method = 'POST';
        let formData = new FormData();
        Object.entries(body).forEach(
            ([key, value]) => {
                formData.append(key, value);
            }
        );
        config.body = formData;
    };
    return new Promise((resolve, reject) => {
            fetch(url, config).then((response) => response.json())
            .then((responseJson) =>  {
                if (responseJson.status >= 200 && responseJson.status < 300) {
                    // Return
                    resolve(responseJson);
                    // Callback - success
                    if (callback) {callback({loading: false, status: responseJson.status})}
                } else {
                    // Return
                    resolve(responseJson);
                    // Callback - warning
                    if (callback) {callback({loading: false, status: responseJson.status})}
                }
            })
            .catch((error) => {
                // Return
                reject(error);
                // Callback - warning
                if (callback) {callback({loading: false, status: 404})}
            });
        }
    );
}