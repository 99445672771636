import React, { Component } from "react";
import * as actionUtilities from '../components/api/index';
import { currencyToDecimal } from 'js-base-utils'
import { GetRequestSWR } from "../lib/fetcher"


import TvCardFull from "../components/cards/TvCardFull"


export default function App() {

  return (
    <div style={{ overflow: "hidden", }}>

      <div style={{ overflow: "hidden", backgroundColor: '#fff', width: '100%', height: "65vh", float: "left" }}>
        <div style={{ backgroundColor: '#333', width: '100%' }}>
          <div style={{ color: '#fff', fontSize: '42px', fontWeight: 'bold', fontFamily: 'Georgia, Serif', textAlign: "center" }}>
            Salas Comerciais para Alugar
          </div>
        </div>
        <div>
          {commercial()}
        </div>
      </div>

      <div style={{ overflow: "hidden", backgroundColor: '#fff', width: '100%', height: "35vh", float: "left" }}>
        <div style={{ backgroundColor: '#333', width: '100%' }}>
          <div style={{ color: '#fff', fontSize: '42px', fontWeight: 'bold', fontFamily: 'Georgia, Serif', textAlign: "center" }}>
            Barracões Comerciais para Alugar
          </div>
        </div>
        <div>
          {warehouse()}
        </div>
      </div>

    </div>
  );
}

function commercial() {

  const { isLoading, data, error, cache } = GetRequestSWR(
    `/static_pages/available_properties?&per_page=1000&status%5B%5D=Disponível&kind_property%5B%5D=Sala Comercial&only_rent=true&city%5B%5D=AGUAÍ`,
    null,
    true,
    null,
    true,
    1000 * 60 * 5,
    true,
  )

  return (
    <div>
      {data?.properties.sort(function (a, b) { return currencyToDecimal(b.total_rent_price) - currencyToDecimal(a.total_rent_price) }).map((property) => (
        <TvCardFull
        property={property}
        cardsPerRow={9}
        marginFraction={1000000000000000000000}
        fontSize={11}
        priceFontSize={11}
        displayDescription={false}
        descriptionFontSize={12}
        descriptionHeightPercentage={0.4}
        topInfoFontSize={15}
        topInfoHeightPercentage={0.16}
        infobarHeightPercentage={0.12}
        addressHeightPercentage={0.09}
        rent_card={true}
        sale_card={false}
        />
      ))}
    </div>
  )
}


function warehouse() {

  const { isLoading, data, error, cache } = GetRequestSWR(
    `/static_pages/available_properties?&per_page=1000&status%5B%5D=Disponível&kind_property%5B%5D=Barracão Comercial&only_rent=true&city%5B%5D=AGUAÍ`,
    null,
    true,
    null,
    true,
    1000 * 60 * 5,
    true,
  )

  return (
    <div>
      {data?.properties.sort(function (a, b) { return currencyToDecimal(b.total_rent_price) - currencyToDecimal(a.total_rent_price) }).map((property) => (
        <TvCardFull
        property={property}
        cardsPerRow={8}
        marginFraction={1000000000000000000000}
        fontSize={12}
        priceFontSize={12}
        displayDescription={true}
        descriptionFontSize={12}
        descriptionHeightPercentage={0.4}
        topInfoFontSize={16}
        topInfoHeightPercentage={0.16}
        infobarHeightPercentage={0.12}
        addressHeightPercentage={0.09}
        rent_card={true}
        sale_card={false}
        />
      ))}
    </div>
  )
}